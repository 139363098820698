"use client";

import Link from "next/link";
import HeaderV2 from "@v2/components/header";
import { Button, Typography } from "./components/ui";
import { Exclamation2Icon } from "./components/icons";
import React, { Suspense } from "react";

export default function GlobalError({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    return (
        <html>
            <body>
                <>
                    <Suspense>
                        {/* <HeaderV2 /> */}
                    </Suspense>
                    <div className="h-[calc(100vh-136px)] flex flex-col items-center justify-center">
                        <div className="flex flex-col items-center justify-center gap-y-8">
                            <Exclamation2Icon size={32} />
                            <Typography>صفحه موردنظر یافت نشد!</Typography>
                            <Link href={`/fa`} prefetch={false} title="بازگشت به صفحه اصلی">
                                <Button variant="contained">بازگشت به صفحه اصلی</Button>
                            </Link>
                        </div>
                    </div>
                </>
                {/* <button onClick={() => reset()}>Try again</button> */}
            </body>
        </html>
    );
}
